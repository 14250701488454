import staticContent from '../content/homePage.json';

export async function getStaticProps() {
  return {
    props: {
      pageMap: staticContent,
    },
  };
}

const HomePage = ({ id, children }) => {
  return <div id={id}>{children}</div>;
};

export default HomePage;
